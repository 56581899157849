*{
    margin : 0;
    padding:0;
}
nav{
    width : 100vw;
    height: 10vh;
    background-color: mediumseagreen;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
    margin-left: 1rem;
    font-size: 2rem;
    font-family:  'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
}
.navList{
    width: 25%;
}
.navList ul{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 1rem;
}
.navList ul li{
    list-style: none;
    cursor: pointer;
    font-family:  'Lucida Sans Unicode',"sans-serif";
    font-weight: 900;
    margin-right: 0.4rem;
    padding: 5px;
}
.navList ul li:hover{
    background-color: antiquewhite;
}
.navList ul a{
    text-decoration: none;
    color: black;
}
.logo a{
    text-decoration: none;
    cursor: pointer;
    color:black;
}