/* myOrderStyles.css */
.my-orders {
    margin: 20px;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

button {
    padding: 10px 15px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #2980b9;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f5f5f5;
}
.totalContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: 1rem auto;
    padding: 10px;
    box-shadow: 4px 4px 8px 6px rgba(255,201,234,0.8);
    background-color: goldenrod;
    border-radius: 2rem 2rem;
}
.tableContainer{
    height: 60vh;
    overflow: auto;
}