.cardContainer {
    width: 14rem;
    height: 24rem;
    border: 1px solid black;
    padding: 0.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin: 1rem;
}

.imageContainer {
    height: 70%;
    width: 100%;
}

.imageContainer img {
    width: 100%;
    height: 100%; /* Adjusted height to fill the container */
    object-fit: center ; /* Maintain aspect ratio and cover container */
    transition: 0.1s ease-in-out;
}
.imageContainer img:hover{
    transform: scale(1.06);
}

.detailContainer{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center; /* Center text content */
}
h4{
    margin-bottom: 10px;
}
button{
    padding:0.5rem;
    cursor: pointer;
    border: None;
    outline: 0;
}
.addBtn{
    width: 101%;
    height: 10%; 
    background-color: rgb(59, 59, 233);
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
}
