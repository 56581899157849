.totalContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: 1rem auto;
    padding: 10px;
    box-shadow: 4px 4px 8px 6px rgba(255,201,234,0.8);
    background-color: goldenrod;
    border-radius: 2rem 2rem;
}
.cartContainer{
    height: 60vh;
    overflow: auto;
}
.cartContainer::-webkit-scrollbar{
    width : 10px;
}
.cartContainer::-webkit-scrollbar-thumb{
    background: linear-gradient(46deg, red,blue); 
    border-radius: 6px;
}
.cartContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scrollbar track */
}
.btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 1rem auto;
    padding: 10px;
    background-color: rgb(18, 39, 226);
    color: #fff;
    font-size: 1.5rem;
}