.panelContainer{
    width:17vw;
    height: auto;
    position: relative;
    left:0.2rem;
    padding: 5px;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.priceFilter{
    margin-left: 0.4rem;
}
.priceFilter input{
    cursor: pointer;
}
.categoryContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-left: 0.4rem;
}
.categoryContainer ul li{
    list-style: none;
    margin: 0.5rem auto;
    border-bottom : 1px solid black ;
    cursor: pointer;
    font-weight: bold;
    padding: 5px;
}
ul li:hover{
    background-color: grey;
}
.clearBtn{
    cursor: pointer;
    margin : 15px;
}