.main{
    width:100vw;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.displayContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    width:70%;
}
.sideContainer {
    width: 20%;
    /* Add the following properties to fix it to the left side */
    position: fixed;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
}
.productContainer{
    display: flex;  
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 5rem;
}

.inputContainer{
    width: 60%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.inputContainer input{
    padding:0.5rem;
    line-height: 0.5;
    letter-spacing: 0.1rem;
    background-color:lightcoral ;
    border: none;
    outline:none;
    width:20rem;
    text-align: center;
    border-radius: 1rem;
    color:black;
    font-size: 1rem;
    font-weight: bold;    
}
.inputContainer input::placeholder{
    color:black;
    font-size: 1rem;
    font-weight: bold;
}
.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Optional: Adjust height to cover the entire viewport */
  }